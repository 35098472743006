import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import SVGImageDefault from "components/Images/SVGImageDefault";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { reportInventories as onGetReportInVentories } from "../../../store/thunks";


const SORT_BY_DEFAULT = 'impression';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportInventory = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            inventories: state.inventories,
            isInventorySuccess: state.isInventorySuccess,
            isInventoryLoading: state.isInventoryLoading,
            error: state.error,
        })
    );

    const { inventories, isInventoryLoading } = useSelector(ReportProperties);
    const [isCallAllOptionLoading, setIsCallAllOptionLoading] = useState<boolean>(true);

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;


    useEffect(() => {
        dispatch(onGetReportInVentories(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD"),
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Rank'),
                accessor: "rank",
                filterable: true,
                sortable: false,
                thWidth: 100,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div className="text-start" style={{ minWidth: '60px' }}>
                            <span>{!!item?.text_row ? '' : ((query?.page - 1) * query?.limit + cell?.row?.index)}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Inventory'),
                accessor: "inventory",
                filterable: false,
                sortable: false,
                thClass: 'align-middle',
                thWidth: 280,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const inventory = cell?.value;
                    const attributes = inventory?.attributes || null;
                    return (
                        <>
                            {!!item?.text_row ? <strong>{item?.text_row}</strong> : (
                                <div className="d-flex align-items-center" style={{ width: '250px' }}>
                                    <div className="flex-shrink-0 me-2 position-relative">
                                        <SVGImageDefault style={{ height: '48px' }} />
                                        {!!inventory?.parent_code ? <span className="position-absolute text-primary fs-14 text-uppercase" style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center' }}>{t('Sub')}</span> : <></>}
                                    </div>
                                    <div>
                                        <div className="ms-2">{inventory?.name || ''}</div>
                                        {/* <strong className="text-muted ms-2">{attributes?.width || item?.width} * {attributes?.height || item?.height}</strong> */}
                                    </div>
                                </div>
                            )}
                        </>
                    )
                },
            },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <div style={{ minWidth: '150px' }}>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: "revenue",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    return (
                        <div style={{ minWidth: '100px' }}>

                        </div>
                    )
                },
            },
        ],
        [i18n?.language, query?.page, query?.limit, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                setIsCallAllOptionLoading((_prev) => false);

            } catch (error: any) {
                setIsCallAllOptionLoading((_prev) => false);
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    useEffect(() => {
        document.title = `${t("Inventory Report")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Inventory Report')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_INVENTORY, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={inventories?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={6} xl={9} xxl={9} className="hstack gap-1 justify-content-end justify-content-sm-start  mt-3 mt-sm-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={(isInventoryLoading || isCallAllOptionLoading)}
                                                    >
                                                        {(isInventoryLoading || isCallAllOptionLoading) ? <div className="me-1" style={{ display: 'inline-table', verticalAlign: 'top' }}><Spinner size="sm" style={{ width: '14px', height: '14px' }}></Spinner></div> : <i className="ri-search-line align-bottom me-1"></i>}{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total sticky-table-report-inventory"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={inventories?.list?.length ? (
                                                [
                                                    {
                                                        ...inventories?.total,
                                                        domain: null,
                                                        text_row: t('Sum')
                                                    },
                                                    ...inventories?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={inventories?.pagination?.total}
                                            customPageCount={Math.ceil(Number(inventories?.pagination?.total) / Number(inventories?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isInventoryLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportInventory;
