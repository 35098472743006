import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from 'api/mediaApi';
import inventoryApi from 'api/inventoryApi';
import { formatQueryParams } from "helpers/format";

export const reportMedias = createAsyncThunk("Report Medias", async (params: any = {}) => {
  try {
    const response = await mediaApi.mediaReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const reportInventories = createAsyncThunk("Report Inventories", async (params: any = {}) => {
  try {
    const response = await inventoryApi.inventoryReports(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});