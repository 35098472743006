import DatePickerCustom from "components/Common/DatePickerCustom";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { reportMedias as onGetReportMedias } from "../../../store/thunks";
import ChartLine from "./ChartLine";


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportMedia = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")),
        end_date: withDefault(StringParam, moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD")),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            medias: state.medias,
            isMediaSuccess: state.isMediaSuccess,
            isMediaLoading: state.isMediaLoading,
            error: state.error,
        })
    );

    const { medias, isMediaLoading } = useSelector(ManagementProperties);

    const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
    const [startDate, endDate] = dateSearch;


    useEffect(() => {
        dispatch(onGetReportMedias(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD"),
            end_date: moment(new Date(new Date().setDate(new Date().getDate() - 1))).format("Y-MM-DD"),
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date()
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    };

    // Column
    const columns = useMemo(
        () => [
            // {
            //     Header: t(''),
            //     accessor: "stt",
            //     filterable: false,
            //     sortable: false,
            //     thWidth: 70,
            //     thClass: 'align-middle text-start',
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             !!item?.text_row ? <></> : <div className="text-start" style={{ minWidth: '50px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index)}</div>
            //         )
            //     },
            // },
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 130,
                thClass: 'text-start align-middle',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const arrDate = String(cell?.value || '').split(' ');
                    const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
                    const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
                    const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
                    return (
                        item?.text_row ? <strong>{item?.text_row}</strong> : <div className="text-start" style={{ minWidth: '130px' }}>
                            <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
                        </div>
                    )
                },
            },
            // {
            //     Header: t('Banner Size'),
            //     accessor: "inventory",
            //     filterable: false,
            //     sortable: false,
            //     thClass: 'align-middle',
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         const inventory = cell?.value;
            //         return (
            //             <>
            //                 {item?.text_row ? <strong>{item?.text_row}</strong> : <div className="d-flex align-items-center" style={{ minWidth: '170px' }}>
            //                     <div className="flex-shrink-0 me-2">
            //                         <SVGImageDefault style={{ height: '48px' }} />
            //                     </div>
            //                     <div>
            //                         <strong className="text-muted ms-2">{inventory?.width} * {inventory?.height}</strong>
            //                     </div>
            //                 </div>}
            //             </>
            //         )
            //     },
            // },
            {
                Header: t('Impression'),
                accessor: "impression",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                // thComponent: () => (
                //     <div className="text-center d-inline-block">
                //         {t('Impression')}
                //         <br />
                //         <span className="fw-normal">({t('Coupang')})</span>
                //     </div>
                // ),
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                            {/* {!!item?.text_row && <div className="text-end pe-3" style={{ minWidth: '100px' }}>({formatNumberWithCommas(item?.impression_coupang)})</div>} */}
                        </>
                    )
                },
            },
            {
                Header: t('Revenue'),
                accessor: "coupang_revenue",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: "revenue",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    return (
                        <div style={{ minWidth: '80px' }}>

                        </div>
                    )
                },
            },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        document.title = `${t("Daily Report")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);


    const categories = useMemo(() => {
        return medias?.list?.map((item: any) => item?.date).reverse() || [];
    }, [medias?.list, i18n?.language]);

    const series = useMemo(() => {
        const obChart = medias?.list?.reduce((ob: any, item: any) => {
            ob.impression?.push(Number((item?.impression).replace(/,/g, '')));
            ob.revenue?.push(Number((item?.coupang_revenue).replace(/,/g, '')));
            return ob;
        }, { impression: [], revenue: [] }) || [];
        return (
            [
                {
                    name: t('Impression'),
                    type: 'line',
                    data: obChart?.impression?.reverse(),
                },
                {
                    name: t('Revenue'),
                    type: 'column',
                    data: obChart?.revenue?.reverse(),
                },

            ]
        )
    }, [medias?.list, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Daily Report')} />
                    <Row>
                        <Col sm={12} md={12}>
                            <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('The yesterday data will be updated around 4 p.m')}</div>
                                <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">
                                                    <span className="me-2">{t('Total')}:</span>
                                                    <CountUp
                                                        start={0}
                                                        end={medias?.pagination?.total || 0}
                                                        duration={1}
                                                        className="text-primary"
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                    <Row className="g-4 align-items-center mt-0">
                                        <Col sm={6} md={6} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-sm-2'>
                                            <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                                                <DatePickerCustom
                                                    placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                    startDate={startDate || null}
                                                    endDate={endDate || null}
                                                    onChangePicker={handleChangePicker}
                                                    showOptions={[
                                                        'today', 'yesterday', 'two_day_ago',
                                                        'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                        'this_week', 'last_week',
                                                        'this_month', 'last_month',
                                                    ]}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={6} md={6} xl={9} xxl={9} className="hstack gap-1 justify-content-end justify-content-sm-start mt-3 mt-sm-2">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-2 "
                                                    onClick={searchData}
                                                    disabled={isMediaLoading}
                                                >
                                                    <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                    {t('Button Search')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary fs-14"
                                                    onClick={resetData}
                                                >
                                                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                    {t('Button Reset')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-3">
                                    <ChartLine
                                        className="mb-5"
                                        titles={[t('Impression'), t('Revenue')]}
                                        categories={categories}
                                        series={series}
                                    />
                                    <TableContainer
                                        className="custom-header-css"
                                        divClass="table-responsive table-card"
                                        tableClass="align-middle table-bordered-dashed table-have-first-row-is-total"
                                        theadClass="table-light text-muted"
                                        columns={columns}
                                        data={medias?.list?.length ? (
                                            [
                                                {
                                                    ...medias?.total,
                                                    impression_coupang: medias?.coupang?.impression,
                                                    click_coupang: medias?.coupang?.click,
                                                    commission_coupang: medias?.coupang?.commission,
                                                    domain: null,
                                                    text_row: t('Sum')
                                                },
                                                ...medias?.list]
                                        ) : []}
                                        customPageSize={query.limit}
                                        customPageIndex={query.page - 1}
                                        totalRecords={medias?.pagination?.total}
                                        customPageCount={Math.ceil(Number(medias?.pagination?.total) / Number(medias?.pagination?.limit))}
                                        handleChangePage={handleChangePage}
                                        manualSorting={true}
                                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                        handleChangeSorting={handleChangeSorting}
                                        isLoading={isMediaLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportMedia;